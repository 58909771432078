import React from 'react'

function NotFoundPage() {
    return (
        <div>
            <h1>Not Found Page</h1>
        </div>
    )
}

export default NotFoundPage;
